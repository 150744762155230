import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { moneyFormatter, roundNumber } from '../../../helpers/formatters';

class Valeurs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPriceByEntity(entity) {
    let { payments } = this.props;
    let debitsValue = 0;
    let creditsValue = 0;

    payments.forEach(p => {
      if (p.entity === entity && p.type === 'debit') debitsValue += p.value;
      if (p.entity === entity && p.type === 'credit') creditsValue += p.value;
    });

    let total =
      entity === 'client'
        ? debitsValue - creditsValue
        : creditsValue - debitsValue;

    return roundNumber(total);
  }

  getPriceTotal() {
    let { payments } = this.props;
    let price = 0;
    let pays = payments.filter(p => p.entity !== 'client');
    pays.forEach(p => {
      price += p.value;
    });
    return roundNumber(price);
  }

  render() {
    let clientTotal = this.getPriceByEntity('client');
    let proTotal = this.getPriceByEntity('pro');
    let placeshakerTotal = this.getPriceByEntity('placeshaker');
    let providerTotal = this.getPriceByEntity('provider');
    let total = roundNumber(
      clientTotal - (proTotal + placeshakerTotal + providerTotal)
    );

    let totalPrix = clientTotal - this.getPriceTotal();

    let totalFormat = moneyFormatter(totalPrix);
    if (totalFormat === '-0,00 €') totalFormat = '0,00 €';

    if (!this.props.approved) return null;

    return (
      <div>
        <Row>
          <Col className={'text-left mt-5'}>
            <h5 className={'m-0'}>
              <Trans>Evolution Devis</Trans>
            </h5>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={2} className={'text-lefT'}>
            <h6>
              <Trans>Total of client</Trans>
            </h6>
          </Col>
          <Col md={10} className={'text-lefT'}>
            <h6>{moneyFormatter(clientTotal)}</h6>
          </Col>

          <Col md={2} className={'text-lefT'}>
            <h6>
              <Trans>Total of pro</Trans>
            </h6>
          </Col>
          <Col md={10} className={'text-lefT'}>
            <h6>{moneyFormatter(proTotal)}</h6>
          </Col>

          <Col md={2} className={'text-lefT'}>
            <h6>
              <Trans>Total of</Trans> Placeshaker
            </h6>
          </Col>
          <Col md={10} className={'text-lefT'}>
            <h6>{moneyFormatter(placeshakerTotal)}</h6>
          </Col>

          <Col md={2} className={'text-lefT'}>
            <h6>
              <Trans>Total of Provider</Trans>
            </h6>
          </Col>
          <Col md={10} className={'text-lefT'}>
            <h6>{moneyFormatter(providerTotal)}</h6>
          </Col>

          <Col md={2} className={'text-lefT'}>
            <h5 className={total >= 0 ? 'text-success' : 'text-danger'}>
              <Trans>Total of work</Trans>
            </h5>
          </Col>
          <Col md={10} className={'text-lefT'}>
            <h5 className={total >= 0 ? 'text-success' : 'text-danger'}>
              {totalFormat}
            </h5>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Valeurs));
