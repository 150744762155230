const boxShadow = '0px 0px 5px -1px rgba(0,0,0,0.75)';

export const canvas_cc = {
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  background: 'Gainsboro',
  display: 'block',
  minHeight: '500px',
  maxHeight: '700px'
};

export const canvas_cdd = {
  zIndex: '777',
  position: 'absolute',
  backgroundColor: 'lightgreen',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

export const canvas_utils = {
  zIndex: '222',
  display: 'inline-block',
  width: '32px',
  position: 'absolute'
};

export const color_select = {
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  border: '1px solid white',
  cursor: 'pointer'
};

export const color_selected = {
  margin: '0 3px 3px',
  position: 'relative',
  float: 'left',
  background: 'gray',
  padding: '5px',
  boxShadow: boxShadow
};

export const colors_list = {
  display: 'inline',
  position: 'absolute',
  margin: 0,
  width: '210px',
  top: 0,
  padding: '5px',
  background: 'gray',
  marginLeft: 30,
  boxShadow: boxShadow
};

export const colors_list_item = {
  display: 'inline',
  float: 'right',
  marginRight: '5px'
};

export const size_select = {
  width: '20px',
  height: '20px',
  cursor: 'pointer',
  textAlign: 'center',
  padding: '2px 0'
};

export const sizes_list = {
  ...colors_list,
  padding: '0 5px',
  width: 222
};

export const sizes_list_item = {
  ...colors_list_item,
  marginRight: 0,
  padding: '7.5px 5px',
  cursor: 'pointer',
  minWidth: 30,
  textAlign: 'center'
};

export const sizes_text = {
  lineHeight: '1',
  fontWeight: 'bold',
  color: 'white',
  fontSize: 15
};

export const opacity_text = {
  ...sizes_text,
  color: 'white',
  fontSize: 20
};

export const opacity_list = {
  ...colors_list,
  padding: '0 5px',
  width: 282
};

export const opacity_list_item = {
  ...colors_list_item,
  marginRight: 0,
  padding: '5px',
  cursor: 'pointer',
  minWidth: 30,
  minHeight: 30,
  textAlign: 'center'
};

export const margin_top_0 = {
  marginTop: '0px'
};

export const margin_bottom_0 = {
  marginBottom: '0px'
};

export const button_menu = {
  background: 'rgb(10,10,10)',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  margin: '3px',
  minWidth: '30px',
  minHeight: '30px',
  WebkitBoxShadow: boxShadow,
  MozBoxShadow: boxShadow,
  boxShadow: boxShadow,
  padding: 0
};

export const button_save = {
  ...button_menu,
  background: 'black',
  padding: '8px'
};

export const select_menu = {
  height: '33px',
  margin: '0 5px',
  background: 'gray',
  color: 'white',
  fontWeight: 'bold'
};

export const create_text = {
  position: 'absolute',
  zIndex: 333,
  boxShadow: '0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)',
  padding: '10px',
  backgroundColor: 'white',
  width: '200px',
  height: '100px'
};

export const create_text_area = {
  //border: 'none',
  resize: 'none',
  width: '100%',
  height: '100%'
};

export const canvas_button_save = {
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 222
};

export default {
  canvas_cc,
  canvas_cdd,
  canvas_utils,
  button_menu,
  button_save,
  select_menu,
  create_text,
  create_text_area,
  margin_top_0,
  margin_bottom_0,
  canvas_button_save,
  color_select,
  color_selected,
  colors_list,
  colors_list_item,
  size_select,
  sizes_list,
  sizes_list_item,
  sizes_text,
  opacity_text,
  opacity_list,
  opacity_list_item
};
