import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';
import Select from 'react-select';
import {
  paymentType,
  paymentEntity,
  paymentStatus
} from '../../../helpers/nomenclators';
import { roundNumber } from '../../../helpers/formatters';

class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: {},
      payments: []
    };
  }

  componentWillMount() {
    let { payment, payments } = this.props;
    if (!payment.type) payment.type = paymentType[0];
    this.setState(ps => ({ ...ps, payment, payments }));
  }

  cleanModal() {
    this.setState({
      dateValid: null,
      valueValid: null,
      payment: {}
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let values = {};
    let { payment, payments } = this.state;

    if (name === 'parent' && payment.type === 'debit') {
      let parent = payments.find(p => p._id === value);
      values = {
        value: roundNumber(
          ((this.props.prices.ttc + this.props.prices.materials) *
            parent.percent) /
            100
        )
      };
    }

    this.setState(ps => ({
      ...ps,
      payment: { ...ps.payment, [name]: value, ...values },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    }));
  }

  validate() {
    let { payment } = this.state;

    if (payment.entity) {
      let date = this.date;
      let value = this.value;
      let description = this.description;

      this.setState({
        [date.attributes.getNamedItem('namevalid').value]: date.validity.valid
          ? 'has-success'
          : 'has-danger',
        [value.attributes.getNamedItem('namevalid').value]: value.validity.valid
          ? 'has-success'
          : 'has-danger',
        [description.attributes.getNamedItem('namevalid').value]: description
          .validity.valid
          ? 'has-success'
          : 'has-danger'
      });

      return (
        date.validity.valid &&
        value.validity.valid &&
        payment.type &&
        //payment.parent &&
        payment.entity
      );
    } else {
      let percent = this.percent;
      let description = this.description;

      this.setState({
        [percent.attributes.getNamedItem('namevalid').value]: percent.validity
          .valid
          ? 'has-success'
          : 'has-danger',
        [description.attributes.getNamedItem('namevalid').value]: description
          .validity.valid
          ? 'has-success'
          : 'has-danger'
      });

      return percent.validity.valid && description.validity.valid;
    }
  }

  onSavePayment() {
    let { payment } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(payment);
    }
  }

  render() {
    let { payment, payments } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {payment && payment._id
            ? payment.entity
              ? t('Edit Payment')
              : t('Edit Payment Plan')
            : payment.entity
            ? t('New Payment')
            : t('New Payment Plan')}
        </ModalHeader>
        <ModalBody>
          {payment ? (
            <Row>
              {payment.entity ? (
                [
                  <Col key={'payment-entity'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Entity</Trans>
                      </Label>
                      <Select
                        className="primary"
                        namevalid="entityValid"
                        value={payment.entity}
                        clearable={false}
                        options={paymentEntity.map(p => ({
                          label: p,
                          value: p
                        }))}
                        onChange={event =>
                          this.onChange(
                            'entity',
                            'entityValid',
                            event ? event.value : null,
                            !!event
                          )
                        }
                      />
                    </FormGroup>
                  </Col>,
                  <Col key={'payment-type'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        innerRef={node => (this.type = node)}
                        className="primary"
                        namevalid="typeValid"
                        clearable={false}
                        value={payment.type}
                        options={paymentType.map(p => ({ label: p, value: p }))}
                        onChange={event =>
                          this.onChange(
                            'type',
                            'typeValid',
                            event ? event.value : null,
                            !!event
                          )
                        }
                      />
                    </FormGroup>
                  </Col>,
                  <Col key={'payment-percent'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Percent</Trans>
                      </Label>
                      <Select
                        disabled={payment.type === 'credit' ? true : false}
                        innerRef={node => (this.parent = node)}
                        className="primary"
                        namevalid="parentValid"
                        clearable={false}
                        value={payment.type === 'debit' ? payment.parent : null}
                        options={payments.map(p => ({
                          label: p.percent,
                          value: p._id
                        }))}
                        onChange={event =>
                          this.onChange(
                            'parent',
                            'parentValid',
                            event ? event.value : null,
                            !!event
                          )
                        }
                      />
                    </FormGroup>
                  </Col>,
                  <Col key={'payment-date'} xs={12} md={4}>
                    <FormGroup
                      className={
                        'has-label form-validation-40 ' + this.state.dateValid
                      }
                    >
                      <Label>
                        <Trans>Date</Trans>
                      </Label>
                      <Datetime
                        locale={'fr'}
                        value={moment(payment.date)}
                        inputProps={{
                          ref: node => (this.date = node),
                          namevalid: 'dateValid',
                          className: 'form-control datetime-read-only',
                          required: 'required',
                          readOnly: true
                        }}
                        onChange={moment =>
                          this.onChange('date', 'dateValid', moment, !!moment)
                        }
                      />
                    </FormGroup>
                  </Col>,
                  <Col key={'payment-amount'} xs={12} md={4}>
                    <FormGroup
                      className={
                        'has-label form-validation-40 ' + this.state.valueValid
                      }
                    >
                      <Label>
                        <Trans>Amount</Trans>
                      </Label>
                      <Input
                        type="number"
                        innerRef={node => (this.value = node)}
                        namevalid="valueValid"
                        value={payment.value || ''}
                        step={0.01}
                        min={0.01}
                        required="required"
                        onChange={event =>
                          this.onChange(
                            'value',
                            'valueValid',
                            event.target.value,
                            event.target.validity.valid
                          )
                        }
                      />
                    </FormGroup>
                  </Col>,
                  <Col key={'payment-status'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Status</Trans>
                      </Label>
                      <Select
                        innerRef={node => (this.parent = node)}
                        className="primary"
                        namevalid="parentValid"
                        value={payment.status || paymentStatus[0]}
                        options={paymentStatus.map(p => ({
                          label: t(p),
                          value: p
                        }))}
                        onChange={event =>
                          this.onChange(
                            'status',
                            'statusValid',
                            event ? event.value : null,
                            !!event
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                ]
              ) : (
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.percentValid
                    }
                  >
                    <Label>
                      <Trans>Percent</Trans>
                      {' %'}
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.percent = node)}
                      namevalid="percentValid"
                      value={payment.percent || ''}
                      step={1}
                      min={1}
                      max={100}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'percent',
                          'percentValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' +
                    this.state.descriptionValid
                  }
                >
                  <Label>
                    <Trans>Description</Trans>
                  </Label>
                  <Input
                    type="textarea"
                    innerRef={node => (this.description = node)}
                    namevalid="descriptionValid"
                    value={payment.description || ''}
                    onChange={event =>
                      this.onChange(
                        'description',
                        'descriptionValid',
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSavePayment(payment)}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(PaymentModal)
);
