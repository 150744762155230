import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Row
} from 'reactstrap';
import { PanelHeader, Spinner } from '../../../components';
import 'moment/locale/fr';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { one, loadAllDevis } from '../../../helpers/actions/projects';
import NavbarProject from '../NavbarActions/NavbarProject';
import Planification from './Planificaton';
import PaymentsByUser from './PaymentsByUser';

import get from 'lodash/get';
import { moneyFormatter } from '../../../helpers/formatters';

class Accounting extends Component {
  state = {
    loading: false,
    project: null,
    devis: [],
    active: ''
  };

  load() {
    this.setState(ps => ({ ...ps, loading: true }));

    let { id } = this.props.match.params,
      { dispatch } = this.props;

    Promise.all([
      dispatch(loadAllDevis(id)),
      dispatch(
        one(id, {
          include: ['provider'],
          fields: [
            'type',
            'name',
            'status',
            'devis',
            'payments',
            'provider',
            'pro',
            'client'
          ]
        })
      )
    ]).then(([{ data }, project]) => {
      let active = data && data.length ? data[0]._id.toString() : '';
      let newData = data.filter(
        d =>
          d.status === 'APPROVED' ||
          d.status === 'CREATED' ||
          d.status === 'SENT'
      );

      this.setState(ps => ({
        ...ps,
        project,
        active,
        loading: false,
        devis: newData
      }));
    });
  }

  getPrice(devis) {
    return get(devis, 'price.ttc', 0) + get(devis, 'price.materials', 0);
  }

  componentDidMount() {
    this.load();
  }

  render() {
    let { loading, project, active, devis } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <NavbarProject project={project} history={this.props.history} />
          <Card>
            {devis && devis.length ? (
              <CardBody>
                <Nav pills className="nav-pills-info">
                  {devis.map((d, key) => {
                    return (
                      <NavItem key={key}>
                        <NavLink
                          className={
                            active === d._id.toString() ? 'active' : ''
                          }
                          onClick={() =>
                            this.setState({ active: d._id.toString() })
                          }
                        >
                          {d.title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
                <hr className={'mb-0'} />
                <TabContent activeTab={active} className="">
                  {devis.map((d, key) => {
                    return (
                      <TabPane key={key} tabId={d._id.toString()}>
                        <Row className={'mb-4 p-2 bg-default text-white'}>
                          <Col className={'text-center'} xs={6} sm={2}>
                            <b>
                              <Trans>Devis Price</Trans>
                            </b>
                          </Col>
                          <Col className={'text-center'} xs={6} sm={2}>
                            <b>
                              <Trans>HT</Trans>: {moneyFormatter(d.price.ttc)}
                            </b>
                          </Col>
                          <Col className={'text-center'} xs={6} sm={2}>
                            <b>
                              <Trans>TTC</Trans>: {moneyFormatter(d.price.ttc)}
                            </b>
                          </Col>
                          <Col className={'text-center'} xs={6} sm={2}>
                            <b>
                              <Trans>TTC + Mat</Trans>:{' '}
                              {moneyFormatter(d.price.ttc + d.price.materials)}
                            </b>
                          </Col>
                          <Col className={'text-center'} xs={6} sm={2}>
                            <b>
                              <Trans>Pro HT</Trans>:{' '}
                              {moneyFormatter(d.price.proHt)}
                            </b>
                          </Col>
                          <Col className={'text-center'} xs={6} sm={2}>
                            <b>
                              <Trans>Pro TTC</Trans>:{' '}
                              {moneyFormatter(d.price.proTtc)}
                            </b>
                          </Col>
                        </Row>

                        <Planification
                          projectId={project._id}
                          devis={d}
                          payments={project.payments.filter(
                            p => p.devis === d._id
                          )}
                        />

                        <PaymentsByUser
                          project={project}
                          provider={project.provider}
                          devis={d}
                          payments={project.payments.filter(
                            p => p.devis === d._id
                          )}
                          approved={project.devis.find(
                            pd => pd.toString() === d._id.toString()
                          )}
                        />
                      </TabPane>
                    );
                  })}
                </TabContent>
              </CardBody>
            ) : (
              <h6 className={'m-3'}>
                <Trans>Not exist any devis</Trans>
              </h6>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Accounting));
