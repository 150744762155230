import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';

import {
  moneyFormatter,
  dateFormatterSmall
} from '../../../helpers/formatters';

class ListPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }

  componentWillMount() {
    let { payments } = this.props;
    this.setState(ps => ({
      ...ps,
      list: payments
    }));
  }

  componentWillReceiveProps(nextProps, prevProps) {
    this.setState(ps => ({
      ...ps,
      list: nextProps.payments
    }));
  }

  render() {
    let { list } = this.state;

    return list.map((p, i) => {
      let value =
        p.type === 'credit' ? (
          <span className={'text-success'}> {moneyFormatter(p.value)}</span>
        ) : (
          <span className={'text-danger'}> {moneyFormatter(p.value)}</span>
        );

      return (
        <Row
          key={`payment-item-${p.type}-${p.enity}-${i}`}
          className={'m-0 pt-2 pb-2 ' + (i % 2 !== 0 ? 'bg-lightgray' : '')}
        >
          <Col
            xs={5}
            sm={5}
            md={5}
            className={'d-flex align-content-center flex-wrap pl-1 pr-2'}
          >
            {p.percent ? <span className={'mr-1'}>{p.percent}% | </span> : ''}
            {value}
          </Col>
          <Col
            xs={3}
            sm={3}
            md={3}
            className={'d-flex align-content-center flex-wrap pl-0'}
          >
            <b className={'m-'}>{p.status}</b>
            {dateFormatterSmall(p.date)}
          </Col>
          <Col xs={4} sm={4} md={4} className={'text-right pl-0 pr-0'}>
            <Button
              className={'m-0 btn-icon bg-transparent text-info'}
              color="info"
              size="sm"
              onClick={() => this.props.updatePayment(p._id)}
            >
              <i className="fas fa-xs fa-pencil-alt icon-action" />
            </Button>
            <Button
              className={'m-0 btn-icon bg-transparent text-danger'}
              color="danger"
              size="sm"
              onClick={() => this.props.removePayment(p._id)}
            >
              <i className="fas fa-xs fa-trash icon-action" />
            </Button>
            <Button
              className={'m-0 btn-icon bg-transparent text-info'}
              color="info"
              size="sm"
              onClick={() => this.props.createBill(p._id)}
            >
              <i className="fas fa-xs fa-file-invoice-dollar icon-action" />
            </Button>
          </Col>
          <Col xs={12} className={'pl-1'}>
            {p.description}
          </Col>
        </Row>
      );
    });
  }
}

export default connect()(translate('translations-fr')(ListPayments));
