import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip
} from 'reactstrap';
import { AlertConfirm, Spinner, TableCustom } from '../../../components';
import get from 'lodash/get';
import filter from 'lodash/filter';
import {
  loadPayments,
  removePayment,
  savePayment
} from '../../../helpers/actions/projects';
import { moneyFormatter } from '../../../helpers/formatters';
import PaymentModal from './PaymentModal';

class Planification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payments: [],
      paymentEdit: null,
      paymentDelete: null,
      paymentUpdate: null,
      price: 0
    };
  }

  getPrice(devis) {
    return get(devis, 'price.ttc', 0) + get(devis, 'price.materials', 0);
  }

  componentDidMount() {
    let { payments, devis } = this.props;

    if (!payments) this.load();
    else {
      let price = this.getPrice(devis);
      let plannedPayments = this.buildPayments(payments);

      this.setState(ps => ({
        ...ps,
        loading: false,
        payments: plannedPayments,
        price
      }));
    }
  }

  buildPayments(payments) {
    let plannedPayments = filter(payments, p => {
      return !p.parent && !p.entity;
    });

    payments.forEach(payment => {
      if (payment.parent) {
        let index = plannedPayments.findIndex(t => t._id === payment.parent);

        if (index > -1) {
          if (!plannedPayments[index].paid) plannedPayments[index].paid = [];

          plannedPayments[index].paid.push(payment);
        }
      }
    });

    return plannedPayments;
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, projectId, devis } = this.props;

    dispatch(loadPayments(projectId, { devis: devis._id }))
      .then(payments => {
        let price = this.getPrice(devis);
        let plannedPayments = this.buildPayments(payments);
        this.setState({
          payments: plannedPayments,
          price,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  showAddPaymentBase() {
    this.setState({ paymentEdit: {} });
  }

  savePayment(payment) {
    this.setState({ loading: true });
    let { dispatch, projectId, devis } = this.props;

    if (payment.parent) {
      let parentPayment = this.state.payments.find(
        p => payment.parent && p._id === payment.parent
      );
      payment = {
        ...payment,
        percent: parentPayment.percent
      };
    }

    payment.devis = devis._id;

    delete payment.user;
    dispatch(savePayment(projectId, payment))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  showRemovePayment(id) {
    this.setState(ps => ({ ...ps, paymentDelete: { _id: id } }));
  }

  showUpdatePayment(id) {
    this.setState(ps => ({ ...ps, paymentUpdate: { _id: id } }));
  }

  remove(id) {
    this.setState({ loading: true });
    let { paymentDelete } = this.state,
      { dispatch, projectId } = this.props;

    dispatch(removePayment(projectId, paymentDelete._id))
      .then(() => this.setState({ paymentDelete: null }, () => this.load()))
      .catch(() => this.setState({ paymentDelete: null, loading: false }));
  }

  getPaymentsData() {
    let { payments, price } = this.state,
      { t } = this.props;

    return payments.map((payment, key) => {
      return {
        className: '',
        data: [
          { className: '', fixed: true, value: payment.percent + '%' },
          {
            className: '',
            fixed: true,
            value: `${moneyFormatter((price * payment.percent) / 100)}`
          },
          { className: '', value: t(payment.description) },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            properties: { width: '15%' },
            value: [
              <Button
                key={`edit-${payment._id}`}
                id={'edit' + payment._id}
                className={'m-1 btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.setState({ paymentEdit: payment })}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`editTooltip-${payment._id}`}
                placement="left"
                target={'edit' + payment._id}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>,
              <Button
                key={`delete-${payment._id}`}
                id={'delete' + payment._id}
                className={'m-1 btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.setState({ paymentDelete: payment })}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
            ]
          }
        ]
      };
    });
  }

  render() {
    let { loading, paymentEdit, paymentDelete, payments } = this.state,
      { t } = this.props;
    let rows = this.getPaymentsData();

    return (
      <div>
        <Card className={'card-plain'}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'p-0 text-right'}>
            <h5 className={'m-0 float-left'}>
              <Trans>Payments Planification</Trans>
            </h5>
            <Button
              className={'mt-0 mb-0'}
              size="sm"
              color={'info'}
              onClick={() => this.showAddPaymentBase()}
            >
              <Trans>Add</Trans>
            </Button>
            <Button
              className={'mt-0 mb-0 ml-2'}
              size="sm"
              color={'default'}
              onClick={() => this.load()}
            >
              <i className="now-ui-icons arrows-1_refresh-69" />
            </Button>
          </CardHeader>

          <hr />

          <CardBody className={'p-0'}>
            <TableCustom
              minWidth={767}
              className={'devi-item-material-table'}
              accordion={true}
              heads={[
                { value: t('Percent') },
                { value: t('Amount') },
                { value: t('Description') },
                { value: '', className: 'text-right' }
              ]}
              rows={rows}
              emptyMessage={t('No payments found')}
            />
          </CardBody>
        </Card>

        {!!paymentEdit ? (
          <PaymentModal
            payments={payments}
            show={!!paymentEdit}
            payment={paymentEdit}
            onCancel={() => this.setState({ paymentEdit: null })}
            onConfirm={payment =>
              this.setState({ paymentEdit: null }, () =>
                this.savePayment(payment)
              )
            }
          />
        ) : null}

        {paymentDelete ? (
          <AlertConfirm
            message={
              paymentDelete.parent
                ? 'The payment cannot be recovered'
                : 'The planned payment and all his payments cannot be recovered'
            }
            onCancel={() => this.setState({ paymentDelete: null })}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Planification));
