import React, { Component } from 'react';
import { Star } from 'react-konva';

class StarComponent extends Component {
  render() {
    return (
      <Star
        key={this.props.id || 'starKey' + Math.random()}
        name={this.props.name || 'starName' + Math.random()}
        x={this.props.x || 100}
        y={this.props.y || 100}
        numPoints={this.props.numPoints || 5}
        innerRadius={this.props.innerRadius || 20}
        outerRadius={this.props.outerRadius || 40}
        fill={this.props.fill || 'black'}
        opacity={this.props.opacity || 0.8}
        draggable={this.props.draggable || false}
        rotation={this.props.rotation || 0}
        shadowColor={this.props.shadowColor || 'black'}
        shadowBlur={this.props.shadowBlur || 10}
        shadowOpacity={this.props.shadowOpacity || 0.6}
        // Events
        onDragStart={e => this.props.handleDragStart(e) || null}
        onDragEnd={e => this.props.handleDragEnd(e) || null}
        onClick={e => this.props.elementClick(e) || null}
      />
    );
  }
}

export default StarComponent;
