import React, { Component } from 'react';
import styles from './styles';

class ContainerButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAnimation: false,
      color: 'black',
      colors: [
        { name: 'black', rgb: [0, 0, 0] },
        { name: 'silver', rgb: [192, 192, 192] },
        { name: 'white', rgb: [255, 255, 255] },
        { name: 'red', rgb: [255, 0, 0] },
        { name: 'blue', rgb: [0, 0, 255] },
        { name: 'cyan', rgb: [0, 255, 255] },
        { name: 'green', rgb: [0, 128, 0] },
        { name: 'yellow', rgb: [255, 255, 0] }
      ],
      size: 4,
      sizes: [1, 2, 4, 8, 16, 32, 64],
      opacity: 0.5,
      opacities: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      showColors: false,
      showSizes: false,
      showOpacities: false
    };
  }

  changeOpacity = value => {
    this.setState(
      ps => ({ ...ps, opacity: value, showOpacities: false }),
      this.props.changeOpacity(value)
    );
  };

  changeSize = value => {
    this.setState(
      ps => ({ ...ps, size: value, showSizes: false }),
      this.props.changeSize(value)
    );
  };

  changeColor = value => {
    this.setState(
      ps => ({ ...ps, color: value, showColors: false }),
      this.props.changeColor(value)
    );
  };

  render() {
    let {
      icons,
      customElements,
      newElement,
      selectOpacity,
      selectSize,
      selectColor,
      stageAction,
      move,
      resizeStage,
      moveImage,
      moveElement,
      select,
      addDraw,
      addElement,
      addForm,
      addLine,
      addText,
      deleteElement
    } = this.props;

    let {
      color,
      colors,
      size,
      sizes,
      opacity,
      opacities,
      showColors,
      showSizes,
      showOpacities
    } = this.state;

    return (
      <div
        key={'canvas-buttons'}
        className={'canvas-buttons'}
        style={{ ...styles.canvas_utils }}
      >
        {/* Actions */}
        {/*icons.zoomIn ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_bottom_0
            }}
            onClick={e => zoom(true)}
          >
            {icons.zoomIn ? icons.zoomIn : '+'}
          </button>
        ) : null*/}
        {/*icons.zoomIn ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0
            }}
            onClick={e => zoom(false)}
          >
            {icons.zoomOut ? icons.zoomOut : '-'}
          </button>
        ) : null*/}
        {icons.zoomResize ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_bottom_0
            }}
            onClick={() => resizeStage('resize')}
          >
            {icons.zoomResize ? icons.zoomResize : 'Resize'}
          </button>
        ) : null}
        {icons.move ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0,
              background: move ? 'gray' : 'black'
            }}
            onClick={() => moveImage()}
          >
            {icons.move ? icons.move : 'Move'}
          </button>
        ) : null}
        {icons.delete ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0,
              background: stageAction === 'delete' ? 'gray' : 'black'
            }}
            onClick={() => deleteElement()}
          >
            {icons.delete ? icons.delete : 'Delete'}
          </button>
        ) : null}
        {icons.select ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              background: stageAction === 'select' ? 'gray' : 'black'
            }}
            onClick={() => select()}
          >
            {icons.select ? icons.select : 'Select'}
          </button>
        ) : null}

        {/* Custom Elements */}
        {customElements.map((ce, index) => (
          <button
            key={'plan-custom-element-' + ce.type}
            title={ce.type}
            style={{
              ...styles.button_menu,
              marginTop: index === 0 ? '5px' : '0',
              marginBottom: index === customElements.length - 1 ? '5px' : '0',
              background:
                stageAction === 'add' && newElement.type === ce.type
                  ? 'gray'
                  : 'SeaGreen'
            }}
            onClick={() => addElement(ce.type, ce.options)}
          >
            {ce.icon}
          </button>
        ))}

        {/* Elements */}
        {icons.line ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_bottom_0,
              background: stageAction === 'line' ? 'gray' : 'SteelBlue'
            }}
            onClick={() => addLine()}
          >
            {icons.line ? icons.line : 'Line'}
          </button>
        ) : null}
        {icons.star ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0,
              background:
                stageAction === 'add' && newElement.type === 'start'
                  ? 'gray'
                  : 'SteelBlue'
            }}
            onClick={() => addElement('start')}
          >
            {icons.star ? icons.star : 'Star'}
          </button>
        ) : null}
        {icons.text ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0,
              background:
                stageAction === 'add' && newElement.type === 'text'
                  ? 'gray'
                  : 'SteelBlue'
            }}
            onClick={() => addText()}
          >
            {icons.text ? icons.text : 'Text'}
          </button>
        ) : null}
        {icons.form ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0,
              background: stageAction === 'form' ? 'gray' : 'SteelBlue'
            }}
            onClick={() => addForm()}
          >
            {icons.form ? icons.form : 'Form'}
          </button>
        ) : null}
        {icons.draw ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0,
              background: stageAction === 'draw' ? 'gray' : 'SteelBlue'
            }}
            onClick={() => addDraw()}
          >
            {icons.draw ? icons.draw : 'Draw'}
          </button>
        ) : null}
        {icons.moveElement ? (
          <button
            style={{
              ...styles.button_menu,
              ...styles.margin_top_0,
              background: stageAction === 'moveElement' ? 'gray' : 'SteelBlue'
            }}
            onClick={() => moveElement()}
          >
            {icons.moveElement ? icons.moveElement : 'Move Element'}
          </button>
        ) : null}

        {/* Design */}
        {selectColor ? (
          <div
            style={{
              ...styles.color_selected,
              marginTop: 5,
              ...styles.margin_bottom_0
            }}
          >
            <div
              style={{ ...styles.color_select, background: `${color}` }}
              onClick={() =>
                this.setState(ps => ({
                  ...ps,
                  showColors: ps.showColors ? false : true
                }))
              }
            />
            {showColors ? (
              <ul style={{ ...styles.colors_list }}>
                {colors.map(c => (
                  <li
                    key={'plan-color-' + c.name}
                    style={{ ...styles.colors_list_item }}
                  >
                    <div
                      style={{
                        ...styles.color_select,
                        background: `${c.name}`
                      }}
                      onClick={() => this.changeColor(c.name)}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </div>
        ) : null}

        {selectSize ? (
          <div
            style={{
              ...styles.color_selected,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0
            }}
          >
            <div
              style={{ ...styles.size_select }}
              onClick={() =>
                this.setState(ps => ({
                  ...ps,
                  showSizes: ps.showSizes ? false : true
                }))
              }
            >
              <div style={{ ...styles.sizes_text }}>{size}</div>
            </div>
            {showSizes ? (
              <ul style={{ ...styles.sizes_list }}>
                {sizes.map(s => (
                  <li
                    key={'plan-color-' + s}
                    style={{ ...styles.sizes_list_item }}
                    onClick={() => this.changeSize(s)}
                  >
                    <div style={{ ...styles.sizes_text }}>{s}</div>
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </div>
        ) : null}

        {selectOpacity ? (
          <div
            style={{
              ...styles.color_selected,
              ...styles.margin_top_0,
              ...styles.margin_bottom_0
            }}
          >
            <div
              style={{
                ...styles.size_select,
                background: `rgba(255,255,255,${opacity})`
              }}
              onClick={() =>
                this.setState(ps => ({
                  ...ps,
                  showOpacities: ps.showOpacities ? false : true
                }))
              }
            >
              <div
                style={{
                  ...styles.opacity_text
                }}
              />
            </div>
            {showOpacities ? (
              <ul style={{ ...styles.opacity_list }}>
                {opacities.map(o => (
                  <li
                    key={'plan-color-' + o}
                    style={{ ...styles.opacity_list_item }}
                    onClick={() => this.changeOpacity(o)}
                  >
                    <div
                      style={{
                        ...styles.opacity_text,
                        background: `rgba(255,255,255,${o})`
                      }}
                    >
                      -
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default ContainerButtons;
