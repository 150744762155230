import React, { Component } from 'react';
import styles from './styles';

class ButtonSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAnimation: false
    };
  }

  render() {
    let { icons, saveAnimation, saveChanges } = this.props;

    return (
      <div
        key={'canvas-button-save'}
        className={'canvas-button-save'}
        style={{ ...styles.canvas_button_save }}
      >
        {icons.save ? (
          <button
            className={saveAnimation ? 'scale-up-center' : ''}
            style={{
              ...styles.button_save,
              background: 'brown'
            }}
            onClick={() => saveChanges()}
          >
            {icons.save ? icons.save : 'Save'}
          </button>
        ) : null}
      </div>
    );
  }
}

export default ButtonSave;
