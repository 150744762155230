import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Button, Row, Card, CardBody, CardHeader } from 'reactstrap';
import { AlertConfirm, Spinner } from '../../../components';
import filter from 'lodash/filter';
import moment from 'moment';
import {
  removePayment,
  savePayment,
  loadPayments
} from '../../../helpers/actions/projects';
import PaymentModal from './PaymentModal';
import PaymentBill from './PaymentBill';
import BlockPayments from './BlockPayments';
import Valeurs from './Valeurs';

class PaymentsByUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payments: [],
      paymentEdit: null,
      paymentDelete: null,
      paymentUpdate: null,
      bill: null,
      paymentBill: null,
      data: []
    };
  }

  componentWillMount() {
    let { payments } = this.props;
    payments = this.buildPayments(payments);
    this.setState(ps => ({
      ...ps,
      loading: false,
      payments
    }));
  }

  componentWillReceiveProps(nextProps, prevProps) {
    let payments = this.buildPayments(nextProps.payments);
    this.setState(ps => ({
      ...ps,
      loading: false,
      payments
    }));
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, project, devis } = this.props;

    dispatch(loadPayments(project._id, { devis: devis._id }))
      .then(payments => {
        payments = this.buildPayments(payments);
        this.setState({
          payments,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  buildPayments(payments) {
    payments = filter(payments, p => p.entity);
    return payments;
  }

  showAddPayment(entity = 'client') {
    this.setState({ paymentEdit: { date: moment(), entity, type: 'debit' } });
  }

  savePayment(payment) {
    this.setState({ loading: true });
    let { dispatch, project, devis } = this.props;

    if (payment.parent) {
      let parentPayment = this.props.payments.find(
        p => payment.parent && p._id === payment.parent
      );
      payment = {
        ...payment,
        percent: parentPayment.percent
      };
    }

    payment.devis = devis._id;

    delete payment.user;
    dispatch(savePayment(project._id, payment))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  showRemovePayment(id) {
    this.setState(ps => ({ ...ps, paymentDelete: { _id: id } }));
  }

  showUpdatePayment(id) {
    let p = this.state.payments.filter(p => p._id === id);
    this.setState(ps => ({ ...ps, paymentEdit: p && p.length ? p[0] : {} }));
  }

  remove() {
    this.setState({ loading: true });
    let { paymentDelete } = this.state,
      { dispatch, project } = this.props;

    dispatch(removePayment(project._id, paymentDelete._id))
      .then(() => this.setState({ paymentDelete: null }, () => this.load()))
      .catch(() => this.setState({ paymentDelete: null, loading: false }));
  }

  showCreateBill(id) {
    let p = this.state.payments.find(p => p._id === id);
    this.setState(ps => ({ ...ps, paymentBill: p }));
  }

  render() {
    let {
      loading,
      paymentEdit,
      paymentDelete,
      paymentBill,
      payments
    } = this.state;

    if (!this.props.approved) return null;

    return (
      <Card className={'card-plain'}>
        {loading ? <Spinner inside={true} /> : null}
        <CardHeader className={'p-0 text-right'}>
          <h5 className={'m-0 float-left'}>
            <Trans>Payments Bill</Trans>
          </h5>
          <Button
            className={'mt-0 mb-0'}
            size="sm"
            color={'info'}
            onClick={() => this.showAddPayment()}
          >
            <Trans>Add</Trans>
          </Button>
          <Button
            className={'mt-0 mb-0 ml-2'}
            size="sm"
            color={'default'}
            onClick={() => this.load()}
          >
            <i className="now-ui-icons arrows-1_refresh-69" />
          </Button>
        </CardHeader>
        <hr />
        <CardBody className={'p-0'}>
          {loading ? <Spinner inside={true} /> : null}
          <Row>
            <BlockPayments
              show={!!this.props.project.client}
              percent={null}
              title={'Client'}
              entity={'client'}
              payments={payments}
              createBill={id => this.showCreateBill(id)}
              showAddPayment={() => this.showAddPayment('client')}
              removePayment={id => this.showRemovePayment(id)}
              updatePayment={id => this.showUpdatePayment(id)}
              firstCredit={false}
            />
            <BlockPayments
              show={!!this.props.project.pro}
              percent={this.props.provider.proPercent || 80}
              title={'Pro'}
              entity={'pro'}
              payments={payments}
              createBill={id => this.showCreateBill(id)}
              showAddPayment={() => this.showAddPayment('pro')}
              removePayment={id => this.showRemovePayment(id)}
              updatePayment={id => this.showUpdatePayment(id)}
              firstCredit={true}
            />
            <BlockPayments
              show={true}
              percent={this.props.provider.commission || 5}
              title={'Placeshaker'}
              entity={'placeshaker'}
              payments={payments}
              createBill={id => this.showCreateBill(id)}
              showAddPayment={() => this.showAddPayment('placeshaker')}
              removePayment={id => this.showRemovePayment(id)}
              updatePayment={id => this.showUpdatePayment(id)}
              firstCredit={true}
            />
            <BlockPayments
              show={!!this.props.project.provider}
              percent={this.props.provider.intermediary || 15}
              title={'Provider'}
              entity={'provider'}
              payments={payments}
              createBill={id => this.showCreateBill(id)}
              showAddPayment={() => this.showAddPayment('provider')}
              removePayment={id => this.showRemovePayment(id)}
              updatePayment={id => this.showUpdatePayment(id)}
              firstCredit={true}
            />
          </Row>

          <Valeurs approved={this.props.approved} payments={payments} />
        </CardBody>

        {!!paymentEdit ? (
          <PaymentModal
            prices={this.props.devis.price || {}}
            payments={this.props.payments.filter(p => !p.entity && p.percent)}
            show={!!paymentEdit}
            payment={paymentEdit}
            onCancel={() => this.setState({ paymentEdit: null })}
            onConfirm={payment =>
              this.setState({ paymentEdit: null }, () =>
                this.savePayment(payment)
              )
            }
          />
        ) : null}

        {paymentBill ? (
          <PaymentBill
            onClose={() => this.setState({ paymentBill: null })}
            payment={paymentBill}
            project={this.props.project._id}
          />
        ) : null}

        {paymentDelete ? (
          <AlertConfirm
            message={
              paymentDelete.parent
                ? 'The payment cannot be recovered'
                : 'The planned payment and all his payments cannot be recovered'
            }
            onCancel={() => this.setState({ paymentDelete: null })}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(PaymentsByUser));
